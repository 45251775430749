.lessonPage {
    margin-bottom: 64px;
}

.lessonPage h1 {
    text-align: center;
    text-transform: uppercase;
    font-size: 28px;
}

.lessonPage .attachments ul {
    list-style: none;
    padding: 0;
    color: var(--ks-color-practices);
}

.lessonPage .attachments ul li a {
    text-decoration: none;
}

.lessonPage .attachments ul li i {
    display: inline-block;
    margin-right: 14px;
}
.lessonPage .feautures {
text-align: center;
color: #656565;
padding-bottom: 10px;
}
.lessonPage .feautures .sp{
    display: inline-block;
    margin: 0 7px;
    width: 1px;
    height: 14px;
    background: #656565;
}