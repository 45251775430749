.homePage .imageRow img {
	width: 100%;
	height: auto;
	vertical-align: middle;
	padding: 5px;
}

.homePage .zigZagText h1 {
	color: rgb(39, 103, 173);
	font-size: 24px;
	/* font-family: 'Patrick Hand', sans-serif; */
}

.homePage .zigZagText p,
.homePage .zigZagText p > span,
.homePage .zigZagText p > b {
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
}

.homePage .imageRow {
	display: flex;
}

.homePage .img1 {
	flex: 0.936;
}

.homePage .img2 {
	flex: 1.05;
}

.homePage .img3 {
	flex: 0.875;
}

.homePage .colSlide-5 .swiper-container .swiper-slide {
	width: calc(20% - 25px) !important;
}

.homePage .colSlide-4 .swiper-container .swiper-slide {
	width: calc(25% - 25px) !important;
}

.homePage .colSlide-3 .swiper-container .swiper-slide {
	width: calc(33.33333% - 25px) !important;
}

.homePage .colSlide-2 .swiper-container .swiper-slide {
	width: calc(50% - 25px) !important;
}

.homePage .colSlide-1 .swiper-container .swiper-slide {
	width: 100% !important;
}

@media only screen and (max-width: 600px) {
	.homePage .zigZagText p {
		font-size: 14px;
	}
}