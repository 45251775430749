.icon-bar {
  position: fixed;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #8dbf67;
}

.icon-bar .facebook {
  background: #3B5998;
  color: white;
}

.icon-bar .twitter {
  background: #55ACEE;
  color: white;
}

.icon-bar .linkedin {
  background: #007bb5;
  color: white;
}

@media only screen and (max-width: 1490px) {
  .icon-bar {
    display: none;
  }
}