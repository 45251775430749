.zig-zag .image {
	max-width: 400px;
	max-height: 80%;
}

.zig-zag .container {
	padding: 64px;
}

.zig-zag .row {
	display: flex;
	align-items: center;
	justify-content: center;
}

.zig-zag .row:after {
	content: "";
	display: table;
	clear: both
}

.zig-zag .column-66 {
	float: left;
	width: 50%;
	padding: 20px;
}

.zig-zag .column-33 {
	float: left;
	width: 50%;
	padding: 20px;
	text-align: center;
}

@media screen and (max-width: 1000px) {
	.zig-zag .row {
		display: block;
	}
	.zig-zag .column-33 {
		padding-bottom: 0;
	}
	.zig-zag .column-66, .zig-zag .column-33 {
		width: 100%;
		text-align: center;
		padding-top: 0;
	}
	.zig-zag .column-66 h1 {
		padding-top: 0;
	}
	.zig-zag .image {
		margin: auto;
		max-width: 300px;
	}
	.zig-zag .row-right {
		display: flex;
		flex-direction: column-reverse;
	}
}

@media screen and (max-width: 600px) {
	.zig-zag .image {
		max-width: 100%;
	}
	.zigZagText h1 {
		font-size: 18px;
	}
}