.postit {
    background: #ffef8b;
    color: #544900;
    max-width: 500px;
    width: 100%;
    margin: 50px auto 0 auto;
    border-radius: 3px;
    font-size: 22px;
    position: relative;
    transform: rotate(1deg);
    box-shadow: 0 30px 60px -30px #464646;
}

.postit:after {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 0;
    height: 0;
    content: "";
    border-width: 21px 0 0 21px;
    border-style: solid;
    border-color: #fff transparent transparent #ffe121;
    transition: border-width 0.2s ease-in;
    box-shadow: -4px 4px 8px -3px #c4aa00;
}

.postit:hover:after {
    border-width: 0;
}

.postit header {
    padding: 10px 20px;
    background: #e3c400;
    color: #544900;
    border-radius: 3px 3px 0 0;
    position: relative;
}

.postit h1 {
    font-family: 'Permanent Marker', cursive;
    font-size: 24px;
    font-weight: normal;
    padding: 0;
    margin: 0;
}

.postit__message {
    padding: 20px;
    margin: 0;
    box-shadow: inset 0 6px 20px -10px #c4aa00;
}