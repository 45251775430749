.slideshow-container {
	width: 100%;
	position: relative;
	padding: 10px;
	margin: auto;
	background-color: rgb(60,143,195);
}

.open-quotes {
	width: 5%;
	height: 20%;
	margin-left: 150px;
}

.quoteSlides {
	width: 90%;
	text-align: center;
	border-radius: 10px;
	overflow: hidden;
	padding: 40px;
	padding-top: 10px;
}

.quoteRow {
	display: flex;
	justify-content: center;
}

.prev, .next {
	cursor: pointer;
	position: absolute;
	top: 50%;
	width: auto;
	margin-top: -100px;
	padding: 5px;
	color: white;
	font-weight: bold;
	font-size: 5vw;
	border-radius: 0 3px 3px 0;
	user-select: none;
  }

.next {
	right: 0;
	border-radius: 3px 0 0 3px;
}



.prev:hover, .next:hover {
	background-color: rgba(0, 0, 0, 0.8);
}

.infoRow {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-wrap: wrap;
	flex-direction: column;
}

.quote {
	font-style: italic;
	color:white;
	font-size: 20px;
	font-family: 'Open Sans', sans-serif;
}

/* .title {
    font-family: 'Patrick Hand', sans-serif;
  } */

.role, .from, .author {
	font-family: 'Open Sans', sans-serif;
	color:white;
	font-size: 13px;
}

.text {
	color: #f2f2f2;
	font-size: 15px;
	padding: 8px 12px;
	position: absolute;
	bottom: 8px;
	width: 100%;
	text-align: center;
}

.numbertext {
	color: #f2f2f2;
	font-size: 12px;
	padding: 8px 12px;
	position: absolute;
	top: 0;
}

@media only screen and (max-width: 600px) {
	.role, .from, .author {
		font-size: 10.5px;
	}

	.quote {
		font-size: 20px;
	}
	
	.prev, .next {
		font-size: 100px;
	}

	.open-quotes {
	}
}