@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");
.dark-background {
	background: #343434;
}

.footer-full {
	background-image: url("../../assets/images/bg-kindergarten.jpg");
	background-size: cover;
	background-attachment: fixed;
	background-position: center;
	color: #fff;
	padding: 0;
	position: absolute;
    bottom: 0;
    width: 100%;
}

.footer-full>div {
	background: rgb(3 143 201 / 82%);
}

.footer-full .footer-social {
	padding: 10px;
	margin: 2px;
	font-size: 30px;
	background: transparent !important;
}

.footer-full .footer-social:hover {
	color: var(--ks-color-concepts);
}

.footer-full footer, .footer-full>div>.row {
	padding: 0 40px;
}

.footer-full>div>.row .footer-menu-item {
	display: inline-block;
	margin-left: 35px;
	cursor: pointer;
}

.footer-full>div>.row .footer-menu-item:hover {
	color: var(--ks-color-concepts);
}

.footer-full>div>.w3-row.footer {
	width: 100%;
	background: #004c6b;
	margin-top: 32px;
	padding: 14px 40px;
}

.footer-full>div>.w3-row.footer p {
	margin: 0;
}

.footer-full::before {
	content: '';
	display: block;
	height: 10px;
	width: 100%;
	background-image: linear-gradient(139deg, var(--ks-color-practices), var(--ks-color-concepts), var(--ks-color-core-ideas));
}

.footer-full .copyright {
	float: left;
}

.footer-full .links:hover {
	cursor: pointer;
}

.footer-full .fa-facebook {
	color: white;
}

.footer-full .fa-linkedin {
	color: white;
}

.footer-full .fa-twitter {
	color: white;
}

.footer-full .footer ul {
	list-style: none;
}

.footer-full .footer li {
	text-transform: uppercase;
}

.footer-full .footer-menu-item:hover {
	color: var(--ks-color-concepts);
}

.footer-full .footer-menu-item span {
	font-size: 16px;
}

.footer-full .copyright-text {
	font-family: sans-serif;
	color: #d2d2d2;
	font-size: 10px;
}

.footer-full .scrollTop {
	background-color: var(--ks-color-concepts);
	border: none;
	position: fixed;
	bottom: 30px;
	right: 30px;
	border-radius: 50%;
	color: white;
	cursor: pointer;
	font-size: 20px;
	line-height: 48px;
	width: 48px;
	transform: translateY(100px);
	transition: all 0.5s ease;
}

.footer-full .showBtn {
	opacity: 1;
	transform: translateY(0);
	z-index: 5;
}

.footer-full .scrollTop:hover {
	opacity: 1;
}

.footer-full .sidepanelFooter a {
	padding: 8px 8px 8px 32px;
	text-decoration: none;
	color: #fff;
	display: block;
	transition: 0.3s;
	font-size: 16px;
}

.footer-full .sidepanelFooter a.active, .footer-full .sidepanelFooter .dropdown-btn:hover, .footer-full .sidepanelFooter a:hover {
	color: var(--ks-color-concepts);
	cursor: pointer;
}

.footer-full .sidepanelFooter .dropdown-btn {
	padding: 8px 8px 8px 32px;
	text-decoration: none;
	font-size: 20px;
	color: #fff;
	display: block;
	border: none;
	background: none;
	width: 100%;
	text-align: left;
	cursor: pointer;
	outline: none;
}

.footer-full .sidepanelFooter .dropdown-btn.active {
	color: var(--ks-color-concepts);
}

.footer-full .sidepanelFooter .dropdown-btn+.dropdown-container {
	display: none;
	background-color: var(--ks-color-core-ideas);
	padding-left: 8px;
}

.footer-full .sidepanelFooter .dropdown-btn.active+.dropdown-container {
	display: block;
	background-color: var(--ks-color-core-ideas);
	padding-left: 8px;
}

.footer-full .sidepanelFooter .fa-caret-up, .sidepanelFooter .fa-caret-down {
	float: right;
	padding-right: 8px;
}

.footer-full .w3-mobile {
	display: none;
}

@media (max-width: 1490px) {
	.footer-full #scrollTopBtn {
		display: none;
	}
	.footer-full .w3-mobile {
		display: block;
	}
}

@media (max-width: 992px) {
	.footer-full footer, .footer-full>div>.row {
		padding: 0 20px;
	}
	.footer-full>div>.w3-row.footer {
		padding: 14px 20px;
	}
	.footer-full .copyright {
		float: none;
	}
	.footer-full .service-menu {
		text-align: center;
	}
	.footer-full .service-menu .footer-menu-item {
		margin-top: 8px;
	}
	.footer-full .service-menu .footer-menu-item:nth-child(2) {
		margin: 0;
	}
}

@media (max-width: 600px) {
	.footer-full {
		text-align: center;
	}
	.footer-full footer, .footer-full>div>.row {
		padding: 0 10px;
	}
	.footer-full>div>.w3-row.footer {
		padding: 14px 10px;
	}
	.footer-full .w3-third.footer.sidepanelFooter {
		display: none;
	}
}