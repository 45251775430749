@keyframes anim-rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

.containerSpinner{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100vh;
}



.img1Spinner{
    /*animation: anim-rotate 2s infinite linear;*/
    position: relative;
}

.img2Spinner{
    position: absolute;
    transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.65);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.65);
	}
}