.ModalAttachment {
	overflow-y: hidden;
}

.ModalAttachment.desktop {
	height: 72px;
	max-height: 72px;
}

.ModalAttachment>span {
	font-weight: 500;
	padding: 8px;
	text-decoration: none;
	overflow-y: hidden;
	cursor: pointer;
	display: flex;
	align-items: flex-end;
}

.ModalAttachment.desktop>span {
	color: var(--ks-color-practices);
	height: 72px;
	max-height: 72px;
	padding: 14px 16px;
}

.ModalAttachment>span i {
	position: relative;
	bottom: 4px;
	margin-right: 4px;
	display: inline-block;
}

.ModalAttachment.desktop>span:hover {
	color: var(--ks-color-concepts);
}

.ModalAttachment.tablet:hover>span {
	color: var(--ks-color-practices);
}

#modale-attachment .cancelbtn {
	width: auto;
	padding: 10px 18px;
	background-color: #f44336;
}

#modale-attachment .imgcontainer {
	height: 42px;
    text-align: center;
    margin: 24px 15px 12px 0;
    position: relative;
}

#modale-attachment img.avatar {
	width: 40%;
	border-radius: 50%;
}

#modale-attachment .container {
	padding: 16px;
}

#modale-attachment .container>p {
	margin: 32px 0;
	text-align: center;
}

#modale-attachment .modal-content {
	background: #fff;
	max-width: 600px;
	margin: 0 auto;
	border-radius: 4px;
	overflow: hidden;
	position: relative;
}

#modale-attachment .input-container+button {
	margin-left: 40px;
}

#modale-attachment .container-button {
	display: flex;
	justify-content: space-between;
}

#modale-attachment span.psw {
	float: right;
	padding-top: 16px;
}

#modale-attachment.modal {
	position: fixed;
	z-index: 999999999;
	left: 0;
	top: 0;
	margin: 0;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
	padding-top: 60px;
}

#modale-attachment {
	background-color: #fefefe;
	margin: 5% auto 15% auto;
	border: 1px solid #888;
	width: 80%;
}

#modale-attachment .close {
	position: absolute;
	right: 2px;
	top: 0;
	color: #000;
	font-size: 35px;
	font-weight: bold;
}

#modale-attachment .close:hover, #modale-attachment .close:focus {
	color: red;
	cursor: pointer;
}

#modale-attachment .animate {
	-webkit-animation: animatezoom 0.6s;
	animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
	from {
		-webkit-transform: scale(0)
	}
	to {
		-webkit-transform: scale(1)
	}
}

@keyframes animatezoom {
	from {
		transform: scale(0)
	}
	to {
		transform: scale(1)
	}
}

@media screen and (max-width: 300px) {
	#modale-attachment span.psw {
		display: block;
		float: none;
	}
	#modale-attachment .cancelbtn {
		width: 100%;
	}
}
.ModalAttachment iframe {
	width: 100%;
    min-height: 600px;
	border: none;
}