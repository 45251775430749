.contactform {
  padding-top: 0 !important;
}

.contactform h1 {
  border-bottom: 1px solid var(--ks-color-practices);
  padding-bottom: 5px;
}

.contactform .title-form.sub {
  margin-left: 45px;
  font-size: 20px;
}

.contactform .city-container, .contactform .address-container {
  display: flex;
}

.contactform .address-container>div:first-child {
  width: 75%;
}

.contactform .address-container>div:last-child {
  width: 25%;
}

.contactform .city-container>div:first-child, .contactform .city-container>div:last-child {
  width: 50%;
}

.contactform .exitbtn {
  float: right;
  background: no-repeat;
  border: none;
}

@media only screen and (max-width: 600px) {
  .contactform .city-container, .contactform .address-container {
    display: block;
  }
  .contactform .address-container>div:first-child, .contactform .address-container>div:last-child, .contactform .city-container>div:first-child, .contactform .city-container>div:last-child {
    width: 100%;
  }
}