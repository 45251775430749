.ModalLogin {
	overflow-y: hidden;
}

.ModalLogin.desktop {
	height: 72px;
	max-height: 72px;
}

.ModalLogin>span {
	font-weight: 500;
	padding: 8px;
	text-decoration: none;
	overflow-y: hidden;
	cursor: pointer;
	display: flex;
	align-items: flex-end;
}

.ModalLogin.desktop>span {
	color: #5d99c6;
	height: 72px;
	padding: 0 10px 10px 0;
}

.ModalLogin>span i {
	position: relative;
	bottom: 4px;
	margin-right: 4px;
	display: inline-block;
}

.ModalLogin.desktop>span:hover {
	color: var(--ks-color-concepts);
}

.ModalLogin.tablet:hover>span {
	color: var(--ks-color-practices);
}

#modale-login .cancelbtn {
	width: auto;
	padding: 10px 18px;
	background-color: #f44336;
}

#modale-login .imgcontainer {
	text-align: center;
	margin: 24px 0 12px 0;
	position: relative;
}

#modale-login img.avatar {
	width: 40%;
	border-radius: 50%;
}

#modale-login .container {
	padding: 16px;
}

#modale-login .container>p {
	margin: 32px 0;
	text-align: center;
}

#modale-login .modal-content {
	background: #fff;
	max-width: 600px;
	margin: 0 auto;
	border-radius: 4px;
}

#modale-login .input-container+button {
	margin-left: 40px;
}

#modale-login .container-button {
	display: flex;
	justify-content: space-between;
}

#modale-login span.psw {
	float: right;
	padding-top: 16px;
}

#modale-login.modal {
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	margin: 0;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
	padding-top: 60px;
}

#modale-login {
	background-color: #fefefe;
	margin: 5% auto 15% auto;
	border: 1px solid #888;
	width: 80%;
}

#modale-login .close {
	position: absolute;
	right: 25px;
	top: 0;
	color: #000;
	font-size: 35px;
	font-weight: bold;
}

#modale-login .close:hover, #modale-login .close:focus {
	color: red;
	cursor: pointer;
}

#modale-login .animate {
	-webkit-animation: animatezoom 0.6s;
	animation: animatezoom 0.6s
}

#modale-login .alert-login {
	color: #404040;
	background-color: var(--ks-color-core-ideas);
}

#modale-login .alert-close {
	display: block;
	float: right;
	font-size: 20px;
	cursor: pointer;
}

#modale-login p {
	margin: 20px 0;
}

@-webkit-keyframes animatezoom {
	from {
		-webkit-transform: scale(0)
	}
	to {
		-webkit-transform: scale(1)
	}
}

@keyframes animatezoom {
	from {
		transform: scale(0)
	}
	to {
		transform: scale(1)
	}
}

@media screen and (max-width: 300px) {
	#modale-login span.psw {
		display: block;
		float: none;
	}
	#modale-login .cancelbtn {
		width: 100%;
	}
}