.nameUser {
	z-index: 300000;
    position: fixed;
    right: 15px;
    color: #5d99c6;
    font-weight: 500;
}

.sideNameUser {
    color: #5d99c6;
	padding: 8px;
}

.sideNameUser span {
    font-size: 15px;
    text-transform: uppercase;
}

.sideNameUser b {
    font-size: 15px;
}
.stickyContainer {
	position: fixed;
	top: 0;
	left: 0;
	margin: auto;
	width: 100%;
	z-index: 100000;
	background-color: #fff;
	min-height: 100px;
	max-height: 100px;
	display: unset;
}

.stickyContainer .rowContainer {
	display: flex;
	height: 100px;
}

.stickyContainer .topnav {
	overflow: hidden;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	align-items: flex-end;
	transition: width .5s;
}

.stickyContainer .topnav a.static {
	color: #5d99c6;
	padding: 0 10px 10px 0;
}

.stickyContainer .topnav span.dropdownItem {
	display: flex;
	color: #5d99c6;
	text-align: left;
	padding: 0 10px 10px 0;
	text-decoration: none;
	height: 72px;
	align-items: flex-end;
}

.stickyContainer .topnav span.dropdownItem i {
	color: var(--fcolor-orange-p);
	top: 85px;
	position: absolute;
	left: calc(50% - 10px);
}

.stickyContainer .topnav span.dropdownItem div.containerDropDown {
	overflow-wrap: break-word;
	width: min-content;
}

.stickyContainer .containerEndSearchOpen i:hover, .stickyContainer .containerEnd i:hover, .stickyContainer .topnav a.active, .stickyContainer .topnav a:hover, .stickyContainer .topnav .dropdown:hover span.dropdownItem div.containerDropDown {
	color: var(--ks-color-concepts);
	cursor: pointer;
}

.stickyContainer .topnav .icon {
	display: none;
}

.stickyContainer .logoContainer {
	width: 216px;
	padding-left: 25px;
}

.stickyContainer .logoContainer img {
	height: 100px;
	width: 216px;
	cursor: pointer;
}

.stickyContainer .ellipsis {
	margin-left: 1rem;
	font-size: 2.3rem;
	cursor: pointer;
	background-color: transparent;
	display: none;
	font-size: 28px;
	margin: 0 5px 0 20px;
}

.stickyContainer .dropbtn {
	background-color: transparent;
	border: none;
	margin-top: 27px;
	cursor: pointer;
	padding: 0;
}

.stickyContainer .dropdown {
	position: relative;
	display: inline-block;
	height: 100%;
}

.stickyContainer .dropdown-content>div {
	display: none;
	position: fixed;
	background-color: #fff;
	min-width: 18rem;
	z-index: 1;
	border-radius: 4px;
	margin-left: -6rem;
	margin-top: 0;
}

.stickyContainer .dropdown-contentItem {
	height: 3rem;
	width: 100%;
	padding: 0 10px;
	display: block;
	text-transform: uppercase;
	text-decoration: none;
}

.stickyContainer .dropdown-contentItem:hover {
	background-color: rgba(141, 191, 103, .33);
}

.stickyContainer .dropdown-contentItemChild {
	height: 100%;
	border-bottom: 1px solid #90caf9;
	color: #5d99c6;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.stickyContainer .containerDropDownContentItem a:last-child div {
	border-bottom: 1px solid transparent;
}

.stickyContainer .dropdown:hover .dropdown-content>div {
	display: block;
}

.stickyContainer .dropdown:hover .dropbtn {
	background-color: transparent;
}

.stickyContainer .containerEnd {
	width: 60px;
	display: flex;
	justify-content: flex-end;
	padding-right: 16px;
	align-items: flex-end;
}

.stickyContainer .containerEndSearchOpen {
	display: flex;
	justify-content: flex-start;
	padding-right: 16px;
	align-items: center;
}

.stickyContainer .containerEnd i {
	padding-bottom: 20px;
	font-size: 22px;
	color: #5d99c6;
}

.stickyContainer .containerEndSearchOpen i {
	font-size: 22px;
	color: var(--ks-color-practices);
}

.stickyContainer .formSearch {
	transition: width .5s;
}

.stickyContainer .formSearch input[type=text] {
	padding: 6px;
	border: none;
	width: 100%;
	color: #797979;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	padding-left: 10px;
	max-height: 42px;
	min-height: 42px;
	border: 2px solid #8dbf67
}

.stickyContainer .containerSearchButton {
	padding-top: 6px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	max-height: 42px;
	min-height: 42px;
	width: 42px;
	background-color: #fff;
	border: 2px solid #8dbf67;
	border-left: transparent;
}

.stickyContainer .containerSearchButton button {
	border: none;
	cursor: pointer;
	background-color: #fff;
}

.sidepanel {
	position: fixed;
	z-index: 20;
	height: 100vh;
	top: 0;
	width: 300px;
	background: rgba(255, 255, 255, 0.95);
	overflow-x: hidden;
	transition: .5s;
	padding-top: 80px;
	border-left: 1px solid #fff;
	border-left-width: 3px;
	border-top-style: solid;
	border-image: linear-gradient(180deg, var(--ks-color-practices), var(--ks-color-concepts), var(--ks-color-core-ideas)) 3;
}

.sidepanel a {
	padding: 8px;
	text-decoration: none;
	font-size: 15px;
	display: block;
	cursor: pointer;
}

.sidepanel a.active, .sidepanel a:hover {
	color: var(--ks-color-practices);
}

.sidepanel .closebtn {
	position: absolute;
	top: 0;
	right: 25px;
	font-size: 36px;
}

.openbtn {
	font-size: 20px;
	cursor: pointer;
	background-color: #111;
	color: white;
	padding: 10px 15px;
	border: none;
}

.openbtn:hover {
	background-color: #444;
}

.sidenav a, .dropdown-btn {
	padding: 8px;
	text-decoration: none;
	font-size: 14px;
	display: block;
	border: none;
	background: none;
	width: 100%;
	text-align: left;
	cursor: pointer;
	outline: none;
}

/* Add an active class to the active dropdown button */

.dropdown-btn.active {
	color: var(--ks-color-practices);
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */

.dropdown-btn+.dropdown-container {
	display: none;
}

.dropdown-btn.active+.dropdown-container {
	display: block;
	padding-left: 18px;
}

/* Optional: Style the caret down icon */

.fa-caret-up, .fa-caret-down {
	float: right;
	padding-right: 8px;
}

.stickyContainer .btnclose-container {
	display: inline-block;
	cursor: pointer;
}

.stickyContainer .btnclose-container .bar1, .stickyContainer .btnclose-container .bar2, .stickyContainer .btnclose-container .bar3 {
	width: 28px;
	height: 4px;
	background-color: var(--ks-color-practices);
	margin: 5px 0;
	transition: 0.4s;
}

.stickyContainer .btnclose-container:hover .bar1, .stickyContainer .btnclose-container:hover .bar2, .stickyContainer .btnclose-container:hover .bar3 {
	background-color: var(--ks-color-concepts);
}

.stickyContainer .btnclose-container.change .bar1 {
	-webkit-transform: rotate(-45deg) translate(-9px, 3px);
	transform: rotate(-45deg) translate(-9px, 3px);
}

.stickyContainer .btnclose-container.change .bar2 {
	opacity: 0;
}

.stickyContainer .btnclose-container.change .bar3 {
	-webkit-transform: rotate(45deg) translate(-8px, -5px);
	transform: rotate(45deg) translate(-8px, -5px);
}

@media only screen and (max-width: 992px) {
	.nameUser {
		display: none;
	}
	.stickyContainer {
		min-height: 80px;
		max-height: 80px;
	}
	.stickyContainer .topnav {
		transition: 0s;
	}
	.stickyContainer .rowContainer {
		height: 80px;
	}
	.stickyContainer .logoContainer {
		width: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-left: 0;
	}
	.stickyContainer .logoContainer img {
		height: 70px;
		width: 70px;
	}
	.stickyContainer .formSearch {
		padding-left: 16px;
	}
	.stickyContainer .containerEndSearchOpen {
		justify-content: flex-end;
	}
}

@media only screen and (max-width: 600px) {
	.stickyContainer .topnav {
		transition: 0s;
	}
}