@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url("https://unpkg.com/swiper/swiper-bundle.min.css");
* {
	font-family: "Source Sans Pro";
	outline: none;
	font-size: 16px;
	--margin-tablet: 20px;
	--margin-mobile: 10px;
}

body {
	min-height: 100vh;
}

html {
	scroll-behavior: smooth;
}

.shadow {
	-webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5) !important;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5) !important;
}

.component-std {
	max-width: 1400px;
	margin: 32px auto 0 auto;
}

.component-full {
	width: 100%;
	overflow: hidden;
	padding: 0 40px;
}

.input-container {
	margin-top: 10px;
	display: flex;
}

.input-container i {
	margin: 22px 10px 0;
	font-size: 18px;
	color: var(--ks-color-practices);
	width: 22px;
}

.input-container input:focus {
	border-color: var(--ks-color-practices);
}

.input-container .custom-select {
	width: 100%;
	margin-top: 11px;
}

.input-container .custom-select .css-tlfecz-indicatorContainer {
	cursor: pointer;
	color: var(--ks-color-practices);
	opacity: 0.6;
}

.input-container .custom-select .css-tlfecz-indicatorContainer:hover {
	opacity: 1;
}

.input-container .custom-select .css-yk16xz-control:hover {
	border-color: #ccc;
}

.input-container .custom-select .css-1pahdxg-control {
	border-color: var(--ks-color-practices) !important;
	box-shadow: none !important;
}

.input-container .custom-select .css-yt9ioa-option, .input-container .custom-select .css-1n7v3ny-option {
	cursor: pointer;
}

.w3-button {
	background-color: var(--ks-color-practices);
	color: white;
	height: 44px;
	border: 1px solid var(--ks-color-practices);
	border-radius: 6px;
	vertical-align: middle;
	text-transform: uppercase;
	-moz-transition: all 0.1s;
	-o-transition: all 0.1s;
	-webkit-transition: all 0.1s;
	transition: all 0.1s;
	position: relative;
	overflow: hidden;
	z-index: 0;
	cursor: pointer;
	font-size: 16px;
}

.w3-button:active {
	background: #0c7dd8;
}

.w3-button:hover>.anim {
	-moz-animation: anim-out 0.75s;
	-webkit-animation: anim-out 0.75s;
	animation: anim-out 0.75s;
}

.w3-button:hover>.anim:after {
	-moz-animation: anim-out-pseudo 0.75s;
	-webkit-animation: anim-out-pseudo 0.75s;
	animation: anim-out-pseudo 0.75s;
}

.w3-button .anim {
	-moz-transform: translateY(-50%) translateX(-50%);
	-ms-transform: translateY(-50%) translateX(-50%);
	-webkit-transform: translateY(-50%) translateX(-50%);
	transform: translateY(-50%) translateX(-50%);
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: -1;
}

.w3-button .anim:before {
	position: relative;
	content: '';
	display: block;
	margin-top: 100%;
}

.w3-button .anim:after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 50%;
}

.w3-button:hover {
	background-color: var(--ks-color-practices) !important;
	color: #fff !important;
}

.w3-button:hover>.anim {
	-moz-animation: anim-out 0.75s;
	-webkit-animation: anim-out 0.75s;
	animation: anim-out 0.75s;
}

.w3-button:hover>.anim:after {
	-moz-animation: anim-out-pseudo 0.75s;
	-webkit-animation: anim-out-pseudo 0.75s;
	animation: anim-out-pseudo 0.75s;
}

.w3-button[disabled] {
	background-color: #e8e8e8 !important;
	color: #7d7d7d !important;
	opacity: 0.8;
}

.w3-button-light {
	background-color: #fff;
	color: var(--ks-color-practices);
	font-weight: 700;
}

.w3-button-light:hover {
	background-color: #fff !important;
	color: var(--ks-color-practices) !important;
}

.w3-button[disabled]:hover {
	opacity: 1;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

textarea {
	width: 100%;
	height: 100px;
	resize: none;
	border: 1px solid #ccc;
	border-left: 3px solid var(--ks-color-practices) !important;
	border-radius: 4px;
}

textarea:focus {
	border: 1px solid var(--ks-color-practices);
}

.margin-top-page {
	margin-top: 64px;
}

.ks-logo {
	text-transform: lowercase;
}

.ks-logo span:first-child, .ks-logo span:last-child {
	text-transform: uppercase;
	font-size: 20px;
	font-weight: bold;
}

.ks-logo span:first-child {
	color: var(--ks-color-practices);
}

.ks-logo span:last-child {
	color: var(--ks-color-concepts);
}

button .load-wrapp {
	width: 100px;
	margin: 0 auto 10px auto;
	border-radius: 5px;
}

button .load-wrapp .line {
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 15px;
	background-color: #fff;
	margin: 6px 2px;
}

button .load-wrapp .load-2 .line:nth-last-child(1) {
	animation: loadingB 1.5s 1s infinite;
}

button .load-wrapp .load-2 .line:nth-last-child(2) {
	animation: loadingB 1.5s 0.5s infinite;
}

button .load-wrapp .load-2 .line:nth-last-child(3) {
	animation: loadingB 1.5s 0s infinite;
}

@media only screen and (max-width: 992px) {
	* {
		font-size: 12px;
	}
	.component-std {
		margin: 45px 20px 0 20px;
	}
	.responsive-m-l {
		margin-left: var(--margin-tablet) !important;
	}
	.component-full {
		margin: 0;
		padding: 0 20px;
	}
}

@media only screen and (max-width: 600px) {
	* {
		font-size: 12px;
	}
	.component-std {
		margin: 45px 10px 0 10px;
	}
	.component-full {
		padding: 0 10px;
	}
	.responsive-m-l {
		margin-left: var(--margin-mobile) !important;
	}
}

@keyframes anim-out {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}

@keyframes anim-out-pseudo {
	0% {
		background: rgba(0, 0, 0, 0.25);
	}
	100% {
		background: transparent;
	}
}

@keyframes loadingB {
	0% {
		width: 15px;
	}
	50% {
		width: 35px;
	}
	100% {
		width: 15px;
	}
}

jodit {
    display: inline-block !important;
}