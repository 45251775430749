@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://unpkg.com/swiper/swiper-bundle.min.css);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
* {
	font-family: "Source Sans Pro";
	outline: none;
	font-size: 16px;
	--margin-tablet: 20px;
	--margin-mobile: 10px;
}

body {
	min-height: 100vh;
}

html {
	scroll-behavior: smooth;
}

.shadow {
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5) !important;
}

.component-std {
	max-width: 1400px;
	margin: 32px auto 0 auto;
}

.component-full {
	width: 100%;
	overflow: hidden;
	padding: 0 40px;
}

.input-container {
	margin-top: 10px;
	display: flex;
}

.input-container i {
	margin: 22px 10px 0;
	font-size: 18px;
	color: var(--ks-color-practices);
	width: 22px;
}

.input-container input:focus {
	border-color: var(--ks-color-practices);
}

.input-container .custom-select {
	width: 100%;
	margin-top: 11px;
}

.input-container .custom-select .css-tlfecz-indicatorContainer {
	cursor: pointer;
	color: var(--ks-color-practices);
	opacity: 0.6;
}

.input-container .custom-select .css-tlfecz-indicatorContainer:hover {
	opacity: 1;
}

.input-container .custom-select .css-yk16xz-control:hover {
	border-color: #ccc;
}

.input-container .custom-select .css-1pahdxg-control {
	border-color: var(--ks-color-practices) !important;
	box-shadow: none !important;
}

.input-container .custom-select .css-yt9ioa-option, .input-container .custom-select .css-1n7v3ny-option {
	cursor: pointer;
}

.w3-button {
	background-color: var(--ks-color-practices);
	color: white;
	height: 44px;
	border: 1px solid var(--ks-color-practices);
	border-radius: 6px;
	vertical-align: middle;
	text-transform: uppercase;
	transition: all 0.1s;
	position: relative;
	overflow: hidden;
	z-index: 0;
	cursor: pointer;
	font-size: 16px;
}

.w3-button:active {
	background: #0c7dd8;
}

.w3-button:hover>.anim {
	-webkit-animation: anim-out 0.75s;
	animation: anim-out 0.75s;
}

.w3-button:hover>.anim:after {
	-webkit-animation: anim-out-pseudo 0.75s;
	animation: anim-out-pseudo 0.75s;
}

.w3-button .anim {
	-webkit-transform: translateY(-50%) translateX(-50%);
	transform: translateY(-50%) translateX(-50%);
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: -1;
}

.w3-button .anim:before {
	position: relative;
	content: '';
	display: block;
	margin-top: 100%;
}

.w3-button .anim:after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 50%;
}

.w3-button:hover {
	background-color: var(--ks-color-practices) !important;
	color: #fff !important;
}

.w3-button:hover>.anim {
	-webkit-animation: anim-out 0.75s;
	animation: anim-out 0.75s;
}

.w3-button:hover>.anim:after {
	-webkit-animation: anim-out-pseudo 0.75s;
	animation: anim-out-pseudo 0.75s;
}

.w3-button[disabled] {
	background-color: #e8e8e8 !important;
	color: #7d7d7d !important;
	opacity: 0.8;
}

.w3-button-light {
	background-color: #fff;
	color: var(--ks-color-practices);
	font-weight: 700;
}

.w3-button-light:hover {
	background-color: #fff !important;
	color: var(--ks-color-practices) !important;
}

.w3-button[disabled]:hover {
	opacity: 1;
	box-shadow: none !important;
}

textarea {
	width: 100%;
	height: 100px;
	resize: none;
	border: 1px solid #ccc;
	border-left: 3px solid var(--ks-color-practices) !important;
	border-radius: 4px;
}

textarea:focus {
	border: 1px solid var(--ks-color-practices);
}

.margin-top-page {
	margin-top: 64px;
}

.ks-logo {
	text-transform: lowercase;
}

.ks-logo span:first-child, .ks-logo span:last-child {
	text-transform: uppercase;
	font-size: 20px;
	font-weight: bold;
}

.ks-logo span:first-child {
	color: var(--ks-color-practices);
}

.ks-logo span:last-child {
	color: var(--ks-color-concepts);
}

button .load-wrapp {
	width: 100px;
	margin: 0 auto 10px auto;
	border-radius: 5px;
}

button .load-wrapp .line {
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 15px;
	background-color: #fff;
	margin: 6px 2px;
}

button .load-wrapp .load-2 .line:nth-last-child(1) {
	-webkit-animation: loadingB 1.5s 1s infinite;
	        animation: loadingB 1.5s 1s infinite;
}

button .load-wrapp .load-2 .line:nth-last-child(2) {
	-webkit-animation: loadingB 1.5s 0.5s infinite;
	        animation: loadingB 1.5s 0.5s infinite;
}

button .load-wrapp .load-2 .line:nth-last-child(3) {
	-webkit-animation: loadingB 1.5s 0s infinite;
	        animation: loadingB 1.5s 0s infinite;
}

@media only screen and (max-width: 992px) {
	* {
		font-size: 12px;
	}
	.component-std {
		margin: 45px 20px 0 20px;
	}
	.responsive-m-l {
		margin-left: var(--margin-tablet) !important;
	}
	.component-full {
		margin: 0;
		padding: 0 20px;
	}
}

@media only screen and (max-width: 600px) {
	* {
		font-size: 12px;
	}
	.component-std {
		margin: 45px 10px 0 10px;
	}
	.component-full {
		padding: 0 10px;
	}
	.responsive-m-l {
		margin-left: var(--margin-mobile) !important;
	}
}

@-webkit-keyframes anim-out {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}

@keyframes anim-out {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}

@-webkit-keyframes anim-out-pseudo {
	0% {
		background: rgba(0, 0, 0, 0.25);
	}
	100% {
		background: transparent;
	}
}

@keyframes anim-out-pseudo {
	0% {
		background: rgba(0, 0, 0, 0.25);
	}
	100% {
		background: transparent;
	}
}

@-webkit-keyframes loadingB {
	0% {
		width: 15px;
	}
	50% {
		width: 35px;
	}
	100% {
		width: 15px;
	}
}

@keyframes loadingB {
	0% {
		width: 15px;
	}
	50% {
		width: 35px;
	}
	100% {
		width: 15px;
	}
}

jodit {
    display: inline-block !important;
}
.jumbotron {
    background: var(--ks-color-practices);
    border-radius: 20px;
    margin-bottom: 32px;
    color: #fff;
    margin-bottom: 32px;
}

@media only screen and (max-width: 992px) {
    .jumbotron h1 {
        font-size: 30px!important;
    }
    .jumbotron h4 {
        font-size: 14px!important;
    }
}

@media only screen and (max-width: 600px) {
    .jumbotron {
        margin: 32px 0 0 0;
        border-radius: 0;
    }
    .jumbotron h1 {
        font-size: 25px!important;
    }
    .jumbotron h4 {
        font-size: 12px!important;
    }
}
.swiper-container {
	width: 100%;
	margin-top: 32px;
	position: relative;
	margin-bottom: 34px;
	overflow: hidden;
}

.swiper-pagination {
	width: 100vw;
    bottom: 4px;
}

.swiper-pagination .swiper-pagination-bullet {
    margin: 0 4px;
}

.swiper-container .swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-container .swiper-slide img {
	width: 100%;
}
.slideshow-container {
	width: 100%;
	position: relative;
	padding: 10px;
	margin: auto;
	background-color: rgb(60,143,195);
}

.open-quotes {
	width: 5%;
	height: 20%;
	margin-left: 150px;
}

.quoteSlides {
	width: 90%;
	text-align: center;
	border-radius: 10px;
	overflow: hidden;
	padding: 40px;
	padding-top: 10px;
}

.quoteRow {
	display: flex;
	justify-content: center;
}

.prev, .next {
	cursor: pointer;
	position: absolute;
	top: 50%;
	width: auto;
	margin-top: -100px;
	padding: 5px;
	color: white;
	font-weight: bold;
	font-size: 5vw;
	border-radius: 0 3px 3px 0;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
  }

.next {
	right: 0;
	border-radius: 3px 0 0 3px;
}



.prev:hover, .next:hover {
	background-color: rgba(0, 0, 0, 0.8);
}

.infoRow {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-wrap: wrap;
	flex-direction: column;
}

.quote {
	font-style: italic;
	color:white;
	font-size: 20px;
	font-family: 'Open Sans', sans-serif;
}

/* .title {
    font-family: 'Patrick Hand', sans-serif;
  } */

.role, .from, .author {
	font-family: 'Open Sans', sans-serif;
	color:white;
	font-size: 13px;
}

.text {
	color: #f2f2f2;
	font-size: 15px;
	padding: 8px 12px;
	position: absolute;
	bottom: 8px;
	width: 100%;
	text-align: center;
}

.numbertext {
	color: #f2f2f2;
	font-size: 12px;
	padding: 8px 12px;
	position: absolute;
	top: 0;
}

@media only screen and (max-width: 600px) {
	.role, .from, .author {
		font-size: 10.5px;
	}

	.quote {
		font-size: 20px;
	}
	
	.prev, .next {
		font-size: 100px;
	}

	.open-quotes {
	}
}
.zig-zag .image {
	max-width: 400px;
	max-height: 80%;
}

.zig-zag .container {
	padding: 64px;
}

.zig-zag .row {
	display: flex;
	align-items: center;
	justify-content: center;
}

.zig-zag .row:after {
	content: "";
	display: table;
	clear: both
}

.zig-zag .column-66 {
	float: left;
	width: 50%;
	padding: 20px;
}

.zig-zag .column-33 {
	float: left;
	width: 50%;
	padding: 20px;
	text-align: center;
}

@media screen and (max-width: 1000px) {
	.zig-zag .row {
		display: block;
	}
	.zig-zag .column-33 {
		padding-bottom: 0;
	}
	.zig-zag .column-66, .zig-zag .column-33 {
		width: 100%;
		text-align: center;
		padding-top: 0;
	}
	.zig-zag .column-66 h1 {
		padding-top: 0;
	}
	.zig-zag .image {
		margin: auto;
		max-width: 300px;
	}
	.zig-zag .row-right {
		display: flex;
		flex-direction: column-reverse;
	}
}

@media screen and (max-width: 600px) {
	.zig-zag .image {
		max-width: 100%;
	}
	.zigZagText h1 {
		font-size: 18px;
	}
}
.player-wrapper {
	padding: 0;
}

.player-wrapper .video-iframe, .player-wrapper .video-iframe>div {
	width: 100% !important;
	height: auto !important;
}

.player-wrapper .video-iframe>div {
	position: relative;
	padding-bottom: 56.25%;
}

.player-wrapper .video-iframe>div>iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-height: 100% !important;
	border: 0;
}
.homePage .imageRow img {
	width: 100%;
	height: auto;
	vertical-align: middle;
	padding: 5px;
}

.homePage .zigZagText h1 {
	color: rgb(39, 103, 173);
	font-size: 24px;
	/* font-family: 'Patrick Hand', sans-serif; */
}

.homePage .zigZagText p,
.homePage .zigZagText p > span,
.homePage .zigZagText p > b {
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
}

.homePage .imageRow {
	display: flex;
}

.homePage .img1 {
	flex: 0.936 1;
}

.homePage .img2 {
	flex: 1.05 1;
}

.homePage .img3 {
	flex: 0.875 1;
}

.homePage .colSlide-5 .swiper-container .swiper-slide {
	width: calc(20% - 25px) !important;
}

.homePage .colSlide-4 .swiper-container .swiper-slide {
	width: calc(25% - 25px) !important;
}

.homePage .colSlide-3 .swiper-container .swiper-slide {
	width: calc(33.33333% - 25px) !important;
}

.homePage .colSlide-2 .swiper-container .swiper-slide {
	width: calc(50% - 25px) !important;
}

.homePage .colSlide-1 .swiper-container .swiper-slide {
	width: 100% !important;
}

@media only screen and (max-width: 600px) {
	.homePage .zigZagText p {
		font-size: 14px;
	}
}
.contactform {
  padding-top: 0 !important;
}

.contactform h1 {
  border-bottom: 1px solid var(--ks-color-practices);
  padding-bottom: 5px;
}

.contactform .title-form.sub {
  margin-left: 45px;
  font-size: 20px;
}

.contactform .city-container, .contactform .address-container {
  display: flex;
}

.contactform .address-container>div:first-child {
  width: 75%;
}

.contactform .address-container>div:last-child {
  width: 25%;
}

.contactform .city-container>div:first-child, .contactform .city-container>div:last-child {
  width: 50%;
}

.contactform .exitbtn {
  float: right;
  background: no-repeat;
  border: none;
}

@media only screen and (max-width: 600px) {
  .contactform .city-container, .contactform .address-container {
    display: block;
  }
  .contactform .address-container>div:first-child, .contactform .address-container>div:last-child, .contactform .city-container>div:first-child, .contactform .city-container>div:last-child {
    width: 100%;
  }
}
.standardsform {
  padding-top: 0 !important;
}

.standardsform h1 {
  border-bottom: 1px solid var(--ks-color-practices);
  padding-bottom: 5px;
}

.standardsform .load-wrapp {
  width: 100px;
  margin: 0 auto 10px auto;
  border-radius: 5px;
}

.standardsform .line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: var(--ks-color-practices);
  margin: 6px 2px;
}

.standardsform .load-2 .line:nth-last-child(1) {
  -webkit-animation: loadingB 1.5s 1s infinite;
          animation: loadingB 1.5s 1s infinite;
}

.standardsform .load-2 .line:nth-last-child(2) {
  -webkit-animation: loadingB 1.5s 0.5s infinite;
          animation: loadingB 1.5s 0.5s infinite;
}

.standardsform .load-2 .line:nth-last-child(3) {
  -webkit-animation: loadingB 1.5s 0s infinite;
          animation: loadingB 1.5s 0s infinite;
}

.standardsform .input2-container {
  display: flex;
}

.standardsform .input2-container>div:first-child, .standardsform .input2-container>div:last-child {
  width: 50%;
}

.standardsform .intro-form {
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .standardsform .input2-container {
    display: block;
  }
  .standardsform .input2-container>div:first-child, .standardsform .input2-container>div:last-child {
    width: 100%;
  }
}

@-webkit-keyframes loadingB {
  0% {
    width: 15px;
  }
  50% {
    width: 35px;
  }
  100% {
    width: 15px;
  }
}

@keyframes loadingB {
  0% {
    width: 15px;
  }
  50% {
    width: 35px;
  }
  100% {
    width: 15px;
  }
}

.standardsform .exitbtn {
  float: right;
  background: no-repeat;
  border: none;
}
.standards-container {
    margin-top: 60px;
    margin-bottom: 60px;
}

.standards-container .w3-row {
    align-items: stretch;
    display: flex;
}

@media (max-width: 991px) {
    .standards-container .w3-row {
        flex-direction: column;
    }
    .standards-container .w3-row .w3-third {
        width: 100%;
    }
}

.standards-container .standard-header {
    background-color: var(--swiper-theme-color);
    color: #fff;
    padding: 0 10px;
}

.standards-container .sep-container {
    background-color: #b8cce4;
}

.standards-container .sep-container h3 {
    background-color: #303996;
}

.standards-container .ccc-container {
    background-color: #d6e3bc;
}

.standards-container .ccc-container h3 {
    background-color: #db711c;
}

.standards-container .dci-container {
    background-color: #fbd4b4;
}

.standards-container .dci-container h3 {
    background-color: #7c9f36;
}

.standards-container .feature-container h3 {
    color: white;
    font-size: 18px;
    padding: 0 4px;
}

.standards-container .feature-container p {
    font-size: 15px;
    margin: 4px 0;
    padding: 0 4px;
}

.standards-container .feature-container p ul {
    margin: 0;
}
@-webkit-keyframes anim-rotate {
	0% {
		-webkit-transform: rotate(0);
		        transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes anim-rotate {
	0% {
		-webkit-transform: rotate(0);
		        transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.containerSpinner{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100vh;
}



.img1Spinner{
    /*animation: anim-rotate 2s infinite linear;*/
    position: relative;
}

.img2Spinner{
    position: absolute;
    -webkit-transform: scale(1);
            transform: scale(1);
	-webkit-animation: pulse 2s infinite;
	        animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.65);
		        transform: scale(0.65);
	}

	70% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	100% {
		-webkit-transform: scale(0.65);
		        transform: scale(0.65);
	}
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(0.65);
		        transform: scale(0.65);
	}

	70% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	100% {
		-webkit-transform: scale(0.65);
		        transform: scale(0.65);
	}
}
.lesson-card {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	max-width: 400px;
	margin: auto;
	text-align: center;
	margin-bottom: 30px;
}

.lesson-card:before {
	content: "";
	background-image: linear-gradient(139deg, var(--ks-color-practices), var(--ks-color-concepts), var(--ks-color-core-ideas));
	height: 1px;
	display: block;
}

.lesson-card .content {
	padding: 8px;
}

.lesson-card .content hr {
	margin: 6px 0;
}

.lesson-card .attachments {
	text-align: left;
	font-size: 14px;
	color: #929292;
}

.lesson-card img {
	width: 100%;
}

.lesson-card .attach-indicators {
	display: flex;
}

.lesson-card .attach-indicators span {
	display: block;
	min-width: 50%;
}

.lesson-card button {
	border: none;
	outline: 0;
	padding: 12px;
	color: white;
	background-color: rgb(39, 103, 173);
	text-align: center;
	cursor: pointer;
	max-width: 300px;
	font-size: 16px;
}

.lesson-card p {
	padding: 10px;
}

.lesson-card button:hover {
	opacity: 0.7;
}

.lesson-card p, .lesson-card h1, .lesson-card a {
	margin: 0 !important;
}

.lesson-card h1 {
	font-size: 22px;
	text-transform: uppercase;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media only screen and (max-width: 600px) {
	.lesson-card h1 {
		font-size: 16px;
	}
	.lesson-card p {
		font-size: 14px;
	}
	.lesson-card button {
		font-size: 14px;
	}
}
.lessonsPage {
	min-height: 100vh;
}

.lessonsPage .column {
	float: left;
	width: 33%;
	padding: 0 10px;
}

.lessonsPage .row {
	margin: 0 -5px;
	display: flex;
    flex-basis: 30%;
    flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
	.lessonsPage .column {
		width: 100%;
		display: block;
		margin-bottom: 20px;
	}
}

.grade_pre-kindergarten .grade-class-1,
.grade_pre-kindergarten .grade-class-2,
.grade_pre-kindergarten .grade-class-3,
.grade_pre-kindergarten .grade-class-4,
.grade_pre-kindergarten .grade-class-5,
.grade_pre-kindergarten .grade-class-middleschool,
.grade_pre-kindergarten .grade-class-kindergarten,
.grade_kindergarten .grade-class-1,
.grade_kindergarten .grade-class-2,
.grade_kindergarten .grade-class-3,
.grade_kindergarten .grade-class-4,
.grade_kindergarten .grade-class-5,
.grade_kindergarten .grade-class-middleschool,
.grade_kindergarten .grade-class-pre-kindergarten,
.grade_1 .grade-class-kindergarten,
.grade_1 .grade-class-2,
.grade_1 .grade-class-3,
.grade_1 .grade-class-4,
.grade_1 .grade-class-5,
.grade_1 .grade-class-middleschool,
.grade_1 .grade-class-pre-kindergarten,
.grade_2 .grade-class-kindergarten,
.grade_2 .grade-class-1,
.grade_2 .grade-class-3,
.grade_2 .grade-class-4,
.grade_2 .grade-class-5,
.grade_2 .grade-class-middleschool,
.grade_2 .grade-class-pre-kindergarten,
.grade_3 .grade-class-kindergarten,
.grade_3 .grade-class-1,
.grade_3 .grade-class-2,
.grade_3 .grade-class-4,
.grade_3 .grade-class-5,
.grade_3 .grade-class-middleschool,
.grade_3 .grade-class-pre-kindergarten,
.grade_4 .grade-class-kindergarten,
.grade_4 .grade-class-1,
.grade_4 .grade-class-2,
.grade_4 .grade-class-3,
.grade_4 .grade-class-5,
.grade_4 .grade-class-middleschool,
.grade_4 .grade-class-pre-kindergarten,
.grade_5 .grade-class-kindergarten,
.grade_5 .grade-class-1,
.grade_5 .grade-class-2,
.grade_5 .grade-class-3,
.grade_5 .grade-class-4,
.grade_5 .grade-class-middleschool,
.grade_5 .grade-class-pre-kindergarten,
.grade_middleschool .grade-class-kindergarten,
.grade_middleschool .grade-class-1,
.grade_middleschool .grade-class-2,
.grade_middleschool .grade-class-3,
.grade_middleschool .grade-class-4,
.grade_middleschool .grade-class-5,
.grade_middleschool .grade-class-pre-kindergarten,
.discipline_PS .discipline-class-LS,
.discipline_PS .discipline-class-ESS,
.discipline_LS .discipline-class-PS,
.discipline_LS .discipline-class-ESS,
.discipline_ESS .discipline-class-PS,
.discipline_ESS .discipline-class-LS {
	display: none;
}

.lessonsPage .filters-area {
	display: flex;
}

.lessonsPage .filters-area > div {
	width: 50%;
    padding: 0 10px;
}

@media screen and (max-width: 600px) {
	.lessonsPage .filters-area {
		flex-direction: column;
	}
	.lessonsPage .filters-area > div {
		width: 100%;
		margin-top: 10px;
	}
}
.searchResult .containerSearchResult {
    flex-wrap: wrap;
}

.searchResult.noResult {
    background: var(--ks-color-practices);
    color: #fff;
    border-radius: 6px;
    height: 80px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin-top: 68px;
    align-items: center;
}

.searchResult .card-wrapper {
    padding: 10px;
}

.searchResult .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding-bottom: 10px;
    border-radius: 4px;
}

.searchResult .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.searchResult .container {
    padding: 2px 16px;
}

.searchResult .container h2 {
    color: var(--ks-color-practices);
    text-transform: uppercase;
    font-size: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.searchResult .container h2 span {
    color: #ccc;
}

.searchResult .containerSearchResult {
    display: flex;
}

.searchResult .title {
    font-size: 20px !important;
    color: var(--ks-color-practices);
}

@media only screen and (max-width: 600px) {
    .searchResult .containerSearchResult {
        flex-direction: column;
    }
}
.ModalAttachment {
	overflow-y: hidden;
}

.ModalAttachment.desktop {
	height: 72px;
	max-height: 72px;
}

.ModalAttachment>span {
	font-weight: 500;
	padding: 8px;
	text-decoration: none;
	overflow-y: hidden;
	cursor: pointer;
	display: flex;
	align-items: flex-end;
}

.ModalAttachment.desktop>span {
	color: var(--ks-color-practices);
	height: 72px;
	max-height: 72px;
	padding: 14px 16px;
}

.ModalAttachment>span i {
	position: relative;
	bottom: 4px;
	margin-right: 4px;
	display: inline-block;
}

.ModalAttachment.desktop>span:hover {
	color: var(--ks-color-concepts);
}

.ModalAttachment.tablet:hover>span {
	color: var(--ks-color-practices);
}

#modale-attachment .cancelbtn {
	width: auto;
	padding: 10px 18px;
	background-color: #f44336;
}

#modale-attachment .imgcontainer {
	height: 42px;
    text-align: center;
    margin: 24px 15px 12px 0;
    position: relative;
}

#modale-attachment img.avatar {
	width: 40%;
	border-radius: 50%;
}

#modale-attachment .container {
	padding: 16px;
}

#modale-attachment .container>p {
	margin: 32px 0;
	text-align: center;
}

#modale-attachment .modal-content {
	background: #fff;
	max-width: 600px;
	margin: 0 auto;
	border-radius: 4px;
	overflow: hidden;
	position: relative;
}

#modale-attachment .input-container+button {
	margin-left: 40px;
}

#modale-attachment .container-button {
	display: flex;
	justify-content: space-between;
}

#modale-attachment span.psw {
	float: right;
	padding-top: 16px;
}

#modale-attachment.modal {
	position: fixed;
	z-index: 999999999;
	left: 0;
	top: 0;
	margin: 0;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
	padding-top: 60px;
}

#modale-attachment {
	background-color: #fefefe;
	margin: 5% auto 15% auto;
	border: 1px solid #888;
	width: 80%;
}

#modale-attachment .close {
	position: absolute;
	right: 2px;
	top: 0;
	color: #000;
	font-size: 35px;
	font-weight: bold;
}

#modale-attachment .close:hover, #modale-attachment .close:focus {
	color: red;
	cursor: pointer;
}

#modale-attachment .animate {
	-webkit-animation: animatezoom 0.6s;
	animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
	from {
		-webkit-transform: scale(0)
	}
	to {
		-webkit-transform: scale(1)
	}
}

@keyframes animatezoom {
	from {
		-webkit-transform: scale(0);
		        transform: scale(0)
	}
	to {
		-webkit-transform: scale(1);
		        transform: scale(1)
	}
}

@media screen and (max-width: 300px) {
	#modale-attachment span.psw {
		display: block;
		float: none;
	}
	#modale-attachment .cancelbtn {
		width: 100%;
	}
}
.ModalAttachment iframe {
	width: 100%;
    min-height: 600px;
	border: none;
}
.lessonPage {
    margin-bottom: 64px;
}

.lessonPage h1 {
    text-align: center;
    text-transform: uppercase;
    font-size: 28px;
}

.lessonPage .attachments ul {
    list-style: none;
    padding: 0;
    color: var(--ks-color-practices);
}

.lessonPage .attachments ul li a {
    text-decoration: none;
}

.lessonPage .attachments ul li i {
    display: inline-block;
    margin-right: 14px;
}
.lessonPage .feautures {
text-align: center;
color: #656565;
padding-bottom: 10px;
}
.lessonPage .feautures .sp{
    display: inline-block;
    margin: 0 7px;
    width: 1px;
    height: 14px;
    background: #656565;
}
.postit {
    background: #ffef8b;
    color: #544900;
    max-width: 500px;
    width: 100%;
    margin: 50px auto 0 auto;
    border-radius: 3px;
    font-size: 22px;
    position: relative;
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg);
    box-shadow: 0 30px 60px -30px #464646;
}

.postit:after {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 0;
    height: 0;
    content: "";
    border-width: 21px 0 0 21px;
    border-style: solid;
    border-color: #fff transparent transparent #ffe121;
    transition: border-width 0.2s ease-in;
    box-shadow: -4px 4px 8px -3px #c4aa00;
}

.postit:hover:after {
    border-width: 0;
}

.postit header {
    padding: 10px 20px;
    background: #e3c400;
    color: #544900;
    border-radius: 3px 3px 0 0;
    position: relative;
}

.postit h1 {
    font-family: 'Permanent Marker', cursive;
    font-size: 24px;
    font-weight: normal;
    padding: 0;
    margin: 0;
}

.postit__message {
    padding: 20px;
    margin: 0;
    box-shadow: inset 0 6px 20px -10px #c4aa00;
}
.ModalLogin {
	overflow-y: hidden;
}

.ModalLogin.desktop {
	height: 72px;
	max-height: 72px;
}

.ModalLogin>span {
	font-weight: 500;
	padding: 8px;
	text-decoration: none;
	overflow-y: hidden;
	cursor: pointer;
	display: flex;
	align-items: flex-end;
}

.ModalLogin.desktop>span {
	color: #5d99c6;
	height: 72px;
	padding: 0 10px 10px 0;
}

.ModalLogin>span i {
	position: relative;
	bottom: 4px;
	margin-right: 4px;
	display: inline-block;
}

.ModalLogin.desktop>span:hover {
	color: var(--ks-color-concepts);
}

.ModalLogin.tablet:hover>span {
	color: var(--ks-color-practices);
}

#modale-login .cancelbtn {
	width: auto;
	padding: 10px 18px;
	background-color: #f44336;
}

#modale-login .imgcontainer {
	text-align: center;
	margin: 24px 0 12px 0;
	position: relative;
}

#modale-login img.avatar {
	width: 40%;
	border-radius: 50%;
}

#modale-login .container {
	padding: 16px;
}

#modale-login .container>p {
	margin: 32px 0;
	text-align: center;
}

#modale-login .modal-content {
	background: #fff;
	max-width: 600px;
	margin: 0 auto;
	border-radius: 4px;
}

#modale-login .input-container+button {
	margin-left: 40px;
}

#modale-login .container-button {
	display: flex;
	justify-content: space-between;
}

#modale-login span.psw {
	float: right;
	padding-top: 16px;
}

#modale-login.modal {
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	margin: 0;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
	padding-top: 60px;
}

#modale-login {
	background-color: #fefefe;
	margin: 5% auto 15% auto;
	border: 1px solid #888;
	width: 80%;
}

#modale-login .close {
	position: absolute;
	right: 25px;
	top: 0;
	color: #000;
	font-size: 35px;
	font-weight: bold;
}

#modale-login .close:hover, #modale-login .close:focus {
	color: red;
	cursor: pointer;
}

#modale-login .animate {
	-webkit-animation: animatezoom 0.6s;
	animation: animatezoom 0.6s
}

#modale-login .alert-login {
	color: #404040;
	background-color: var(--ks-color-core-ideas);
}

#modale-login .alert-close {
	display: block;
	float: right;
	font-size: 20px;
	cursor: pointer;
}

#modale-login p {
	margin: 20px 0;
}

@-webkit-keyframes animatezoom {
	from {
		-webkit-transform: scale(0)
	}
	to {
		-webkit-transform: scale(1)
	}
}

@keyframes animatezoom {
	from {
		-webkit-transform: scale(0);
		        transform: scale(0)
	}
	to {
		-webkit-transform: scale(1);
		        transform: scale(1)
	}
}

@media screen and (max-width: 300px) {
	#modale-login span.psw {
		display: block;
		float: none;
	}
	#modale-login .cancelbtn {
		width: 100%;
	}
}
.nameUser {
	z-index: 300000;
    position: fixed;
    right: 15px;
    color: #5d99c6;
    font-weight: 500;
}

.sideNameUser {
    color: #5d99c6;
	padding: 8px;
}

.sideNameUser span {
    font-size: 15px;
    text-transform: uppercase;
}

.sideNameUser b {
    font-size: 15px;
}
.stickyContainer {
	position: fixed;
	top: 0;
	left: 0;
	margin: auto;
	width: 100%;
	z-index: 100000;
	background-color: #fff;
	min-height: 100px;
	max-height: 100px;
	display: unset;
}

.stickyContainer .rowContainer {
	display: flex;
	height: 100px;
}

.stickyContainer .topnav {
	overflow: hidden;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	transition: width .5s;
}

.stickyContainer .topnav a.static {
	color: #5d99c6;
	padding: 0 10px 10px 0;
}

.stickyContainer .topnav span.dropdownItem {
	display: flex;
	color: #5d99c6;
	text-align: left;
	padding: 0 10px 10px 0;
	text-decoration: none;
	height: 72px;
	align-items: flex-end;
}

.stickyContainer .topnav span.dropdownItem i {
	color: var(--fcolor-orange-p);
	top: 85px;
	position: absolute;
	left: calc(50% - 10px);
}

.stickyContainer .topnav span.dropdownItem div.containerDropDown {
	overflow-wrap: break-word;
	width: -webkit-min-content;
	width: min-content;
}

.stickyContainer .containerEndSearchOpen i:hover, .stickyContainer .containerEnd i:hover, .stickyContainer .topnav a.active, .stickyContainer .topnav a:hover, .stickyContainer .topnav .dropdown:hover span.dropdownItem div.containerDropDown {
	color: var(--ks-color-concepts);
	cursor: pointer;
}

.stickyContainer .topnav .icon {
	display: none;
}

.stickyContainer .logoContainer {
	width: 216px;
	padding-left: 25px;
}

.stickyContainer .logoContainer img {
	height: 100px;
	width: 216px;
	cursor: pointer;
}

.stickyContainer .ellipsis {
	margin-left: 1rem;
	font-size: 2.3rem;
	cursor: pointer;
	background-color: transparent;
	display: none;
	font-size: 28px;
	margin: 0 5px 0 20px;
}

.stickyContainer .dropbtn {
	background-color: transparent;
	border: none;
	margin-top: 27px;
	cursor: pointer;
	padding: 0;
}

.stickyContainer .dropdown {
	position: relative;
	display: inline-block;
	height: 100%;
}

.stickyContainer .dropdown-content>div {
	display: none;
	position: fixed;
	background-color: #fff;
	min-width: 18rem;
	z-index: 1;
	border-radius: 4px;
	margin-left: -6rem;
	margin-top: 0;
}

.stickyContainer .dropdown-contentItem {
	height: 3rem;
	width: 100%;
	padding: 0 10px;
	display: block;
	text-transform: uppercase;
	text-decoration: none;
}

.stickyContainer .dropdown-contentItem:hover {
	background-color: rgba(141, 191, 103, .33);
}

.stickyContainer .dropdown-contentItemChild {
	height: 100%;
	border-bottom: 1px solid #90caf9;
	color: #5d99c6;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.stickyContainer .containerDropDownContentItem a:last-child div {
	border-bottom: 1px solid transparent;
}

.stickyContainer .dropdown:hover .dropdown-content>div {
	display: block;
}

.stickyContainer .dropdown:hover .dropbtn {
	background-color: transparent;
}

.stickyContainer .containerEnd {
	width: 60px;
	display: flex;
	justify-content: flex-end;
	padding-right: 16px;
	align-items: flex-end;
}

.stickyContainer .containerEndSearchOpen {
	display: flex;
	justify-content: flex-start;
	padding-right: 16px;
	align-items: center;
}

.stickyContainer .containerEnd i {
	padding-bottom: 20px;
	font-size: 22px;
	color: #5d99c6;
}

.stickyContainer .containerEndSearchOpen i {
	font-size: 22px;
	color: var(--ks-color-practices);
}

.stickyContainer .formSearch {
	transition: width .5s;
}

.stickyContainer .formSearch input[type=text] {
	padding: 6px;
	border: none;
	width: 100%;
	color: #797979;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	padding-left: 10px;
	max-height: 42px;
	min-height: 42px;
	border: 2px solid #8dbf67
}

.stickyContainer .containerSearchButton {
	padding-top: 6px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	max-height: 42px;
	min-height: 42px;
	width: 42px;
	background-color: #fff;
	border: 2px solid #8dbf67;
	border-left: transparent;
}

.stickyContainer .containerSearchButton button {
	border: none;
	cursor: pointer;
	background-color: #fff;
}

.sidepanel {
	position: fixed;
	z-index: 20;
	height: 100vh;
	top: 0;
	width: 300px;
	background: rgba(255, 255, 255, 0.95);
	overflow-x: hidden;
	transition: .5s;
	padding-top: 80px;
	border-left: 1px solid #fff;
	border-left-width: 3px;
	border-top-style: solid;
	border-image: linear-gradient(180deg, var(--ks-color-practices), var(--ks-color-concepts), var(--ks-color-core-ideas)) 3;
}

.sidepanel a {
	padding: 8px;
	text-decoration: none;
	font-size: 15px;
	display: block;
	cursor: pointer;
}

.sidepanel a.active, .sidepanel a:hover {
	color: var(--ks-color-practices);
}

.sidepanel .closebtn {
	position: absolute;
	top: 0;
	right: 25px;
	font-size: 36px;
}

.openbtn {
	font-size: 20px;
	cursor: pointer;
	background-color: #111;
	color: white;
	padding: 10px 15px;
	border: none;
}

.openbtn:hover {
	background-color: #444;
}

.sidenav a, .dropdown-btn {
	padding: 8px;
	text-decoration: none;
	font-size: 14px;
	display: block;
	border: none;
	background: none;
	width: 100%;
	text-align: left;
	cursor: pointer;
	outline: none;
}

/* Add an active class to the active dropdown button */

.dropdown-btn.active {
	color: var(--ks-color-practices);
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */

.dropdown-btn+.dropdown-container {
	display: none;
}

.dropdown-btn.active+.dropdown-container {
	display: block;
	padding-left: 18px;
}

/* Optional: Style the caret down icon */

.fa-caret-up, .fa-caret-down {
	float: right;
	padding-right: 8px;
}

.stickyContainer .btnclose-container {
	display: inline-block;
	cursor: pointer;
}

.stickyContainer .btnclose-container .bar1, .stickyContainer .btnclose-container .bar2, .stickyContainer .btnclose-container .bar3 {
	width: 28px;
	height: 4px;
	background-color: var(--ks-color-practices);
	margin: 5px 0;
	transition: 0.4s;
}

.stickyContainer .btnclose-container:hover .bar1, .stickyContainer .btnclose-container:hover .bar2, .stickyContainer .btnclose-container:hover .bar3 {
	background-color: var(--ks-color-concepts);
}

.stickyContainer .btnclose-container.change .bar1 {
	-webkit-transform: rotate(-45deg) translate(-9px, 3px);
	transform: rotate(-45deg) translate(-9px, 3px);
}

.stickyContainer .btnclose-container.change .bar2 {
	opacity: 0;
}

.stickyContainer .btnclose-container.change .bar3 {
	-webkit-transform: rotate(45deg) translate(-8px, -5px);
	transform: rotate(45deg) translate(-8px, -5px);
}

@media only screen and (max-width: 992px) {
	.nameUser {
		display: none;
	}
	.stickyContainer {
		min-height: 80px;
		max-height: 80px;
	}
	.stickyContainer .topnav {
		transition: 0s;
	}
	.stickyContainer .rowContainer {
		height: 80px;
	}
	.stickyContainer .logoContainer {
		width: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-left: 0;
	}
	.stickyContainer .logoContainer img {
		height: 70px;
		width: 70px;
	}
	.stickyContainer .formSearch {
		padding-left: 16px;
	}
	.stickyContainer .containerEndSearchOpen {
		justify-content: flex-end;
	}
}

@media only screen and (max-width: 600px) {
	.stickyContainer .topnav {
		transition: 0s;
	}
}
.dark-background {
	background: #343434;
}

.footer-full {
	background-image: url(/static/media/bg-kindergarten.b7ae1308.jpg);
	background-size: cover;
	background-attachment: fixed;
	background-position: center;
	color: #fff;
	padding: 0;
	position: absolute;
    bottom: 0;
    width: 100%;
}

.footer-full>div {
	background: rgb(3 143 201 / 82%);
}

.footer-full .footer-social {
	padding: 10px;
	margin: 2px;
	font-size: 30px;
	background: transparent !important;
}

.footer-full .footer-social:hover {
	color: var(--ks-color-concepts);
}

.footer-full footer, .footer-full>div>.row {
	padding: 0 40px;
}

.footer-full>div>.row .footer-menu-item {
	display: inline-block;
	margin-left: 35px;
	cursor: pointer;
}

.footer-full>div>.row .footer-menu-item:hover {
	color: var(--ks-color-concepts);
}

.footer-full>div>.w3-row.footer {
	width: 100%;
	background: #004c6b;
	margin-top: 32px;
	padding: 14px 40px;
}

.footer-full>div>.w3-row.footer p {
	margin: 0;
}

.footer-full::before {
	content: '';
	display: block;
	height: 10px;
	width: 100%;
	background-image: linear-gradient(139deg, var(--ks-color-practices), var(--ks-color-concepts), var(--ks-color-core-ideas));
}

.footer-full .copyright {
	float: left;
}

.footer-full .links:hover {
	cursor: pointer;
}

.footer-full .fa-facebook {
	color: white;
}

.footer-full .fa-linkedin {
	color: white;
}

.footer-full .fa-twitter {
	color: white;
}

.footer-full .footer ul {
	list-style: none;
}

.footer-full .footer li {
	text-transform: uppercase;
}

.footer-full .footer-menu-item:hover {
	color: var(--ks-color-concepts);
}

.footer-full .footer-menu-item span {
	font-size: 16px;
}

.footer-full .copyright-text {
	font-family: sans-serif;
	color: #d2d2d2;
	font-size: 10px;
}

.footer-full .scrollTop {
	background-color: var(--ks-color-concepts);
	border: none;
	position: fixed;
	bottom: 30px;
	right: 30px;
	border-radius: 50%;
	color: white;
	cursor: pointer;
	font-size: 20px;
	line-height: 48px;
	width: 48px;
	-webkit-transform: translateY(100px);
	        transform: translateY(100px);
	transition: all 0.5s ease;
}

.footer-full .showBtn {
	opacity: 1;
	-webkit-transform: translateY(0);
	        transform: translateY(0);
	z-index: 5;
}

.footer-full .scrollTop:hover {
	opacity: 1;
}

.footer-full .sidepanelFooter a {
	padding: 8px 8px 8px 32px;
	text-decoration: none;
	color: #fff;
	display: block;
	transition: 0.3s;
	font-size: 16px;
}

.footer-full .sidepanelFooter a.active, .footer-full .sidepanelFooter .dropdown-btn:hover, .footer-full .sidepanelFooter a:hover {
	color: var(--ks-color-concepts);
	cursor: pointer;
}

.footer-full .sidepanelFooter .dropdown-btn {
	padding: 8px 8px 8px 32px;
	text-decoration: none;
	font-size: 20px;
	color: #fff;
	display: block;
	border: none;
	background: none;
	width: 100%;
	text-align: left;
	cursor: pointer;
	outline: none;
}

.footer-full .sidepanelFooter .dropdown-btn.active {
	color: var(--ks-color-concepts);
}

.footer-full .sidepanelFooter .dropdown-btn+.dropdown-container {
	display: none;
	background-color: var(--ks-color-core-ideas);
	padding-left: 8px;
}

.footer-full .sidepanelFooter .dropdown-btn.active+.dropdown-container {
	display: block;
	background-color: var(--ks-color-core-ideas);
	padding-left: 8px;
}

.footer-full .sidepanelFooter .fa-caret-up, .sidepanelFooter .fa-caret-down {
	float: right;
	padding-right: 8px;
}

.footer-full .w3-mobile {
	display: none;
}

@media (max-width: 1490px) {
	.footer-full #scrollTopBtn {
		display: none;
	}
	.footer-full .w3-mobile {
		display: block;
	}
}

@media (max-width: 992px) {
	.footer-full footer, .footer-full>div>.row {
		padding: 0 20px;
	}
	.footer-full>div>.w3-row.footer {
		padding: 14px 20px;
	}
	.footer-full .copyright {
		float: none;
	}
	.footer-full .service-menu {
		text-align: center;
	}
	.footer-full .service-menu .footer-menu-item {
		margin-top: 8px;
	}
	.footer-full .service-menu .footer-menu-item:nth-child(2) {
		margin: 0;
	}
}

@media (max-width: 600px) {
	.footer-full {
		text-align: center;
	}
	.footer-full footer, .footer-full>div>.row {
		padding: 0 10px;
	}
	.footer-full>div>.w3-row.footer {
		padding: 14px 10px;
	}
	.footer-full .w3-third.footer.sidepanelFooter {
		display: none;
	}
}
.icon-bar {
  position: fixed;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #8dbf67;
}

.icon-bar .facebook {
  background: #3B5998;
  color: white;
}

.icon-bar .twitter {
  background: #55ACEE;
  color: white;
}

.icon-bar .linkedin {
  background: #007bb5;
  color: white;
}

@media only screen and (max-width: 1490px) {
  .icon-bar {
    display: none;
  }
}
