.lesson-card {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	max-width: 400px;
	margin: auto;
	text-align: center;
	margin-bottom: 30px;
}

.lesson-card:before {
	content: "";
	background-image: linear-gradient(139deg, var(--ks-color-practices), var(--ks-color-concepts), var(--ks-color-core-ideas));
	height: 1px;
	display: block;
}

.lesson-card .content {
	padding: 8px;
}

.lesson-card .content hr {
	margin: 6px 0;
}

.lesson-card .attachments {
	text-align: left;
	font-size: 14px;
	color: #929292;
}

.lesson-card img {
	width: 100%;
}

.lesson-card .attach-indicators {
	display: flex;
}

.lesson-card .attach-indicators span {
	display: block;
	min-width: 50%;
}

.lesson-card button {
	border: none;
	outline: 0;
	padding: 12px;
	color: white;
	background-color: rgb(39, 103, 173);
	text-align: center;
	cursor: pointer;
	max-width: 300px;
	font-size: 16px;
}

.lesson-card p {
	padding: 10px;
}

.lesson-card button:hover {
	opacity: 0.7;
}

.lesson-card p, .lesson-card h1, .lesson-card a {
	margin: 0 !important;
}

.lesson-card h1 {
	font-size: 22px;
	text-transform: uppercase;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media only screen and (max-width: 600px) {
	.lesson-card h1 {
		font-size: 16px;
	}
	.lesson-card p {
		font-size: 14px;
	}
	.lesson-card button {
		font-size: 14px;
	}
}