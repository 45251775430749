.swiper-container {
	width: 100%;
	margin-top: 32px;
	position: relative;
	margin-bottom: 34px;
	overflow: hidden;
}

.swiper-pagination {
	width: 100vw;
    bottom: 4px;
}

.swiper-pagination .swiper-pagination-bullet {
    margin: 0 4px;
}

.swiper-container .swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-container .swiper-slide img {
	width: 100%;
}