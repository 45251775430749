.player-wrapper {
	padding: 0;
}

.player-wrapper .video-iframe, .player-wrapper .video-iframe>div {
	width: 100% !important;
	height: auto !important;
}

.player-wrapper .video-iframe>div {
	position: relative;
	padding-bottom: 56.25%;
}

.player-wrapper .video-iframe>div>iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-height: 100% !important;
	border: 0;
}