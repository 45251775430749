.jumbotron {
    background: var(--ks-color-practices);
    border-radius: 20px;
    margin-bottom: 32px;
    color: #fff;
    margin-bottom: 32px;
}

@media only screen and (max-width: 992px) {
    .jumbotron h1 {
        font-size: 30px!important;
    }
    .jumbotron h4 {
        font-size: 14px!important;
    }
}

@media only screen and (max-width: 600px) {
    .jumbotron {
        margin: 32px 0 0 0;
        border-radius: 0;
    }
    .jumbotron h1 {
        font-size: 25px!important;
    }
    .jumbotron h4 {
        font-size: 12px!important;
    }
}