.standardsform {
  padding-top: 0 !important;
}

.standardsform h1 {
  border-bottom: 1px solid var(--ks-color-practices);
  padding-bottom: 5px;
}

.standardsform .load-wrapp {
  width: 100px;
  margin: 0 auto 10px auto;
  border-radius: 5px;
}

.standardsform .line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: var(--ks-color-practices);
  margin: 6px 2px;
}

.standardsform .load-2 .line:nth-last-child(1) {
  animation: loadingB 1.5s 1s infinite;
}

.standardsform .load-2 .line:nth-last-child(2) {
  animation: loadingB 1.5s 0.5s infinite;
}

.standardsform .load-2 .line:nth-last-child(3) {
  animation: loadingB 1.5s 0s infinite;
}

.standardsform .input2-container {
  display: flex;
}

.standardsform .input2-container>div:first-child, .standardsform .input2-container>div:last-child {
  width: 50%;
}

.standardsform .intro-form {
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .standardsform .input2-container {
    display: block;
  }
  .standardsform .input2-container>div:first-child, .standardsform .input2-container>div:last-child {
    width: 100%;
  }
}

@keyframes loadingB {
  0% {
    width: 15px;
  }
  50% {
    width: 35px;
  }
  100% {
    width: 15px;
  }
}

.standardsform .exitbtn {
  float: right;
  background: no-repeat;
  border: none;
}