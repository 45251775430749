.lessonsPage {
	min-height: 100vh;
}

.lessonsPage .column {
	float: left;
	width: 33%;
	padding: 0 10px;
}

.lessonsPage .row {
	margin: 0 -5px;
	display: flex;
    flex-basis: 30%;
    flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
	.lessonsPage .column {
		width: 100%;
		display: block;
		margin-bottom: 20px;
	}
}

.grade_pre-kindergarten .grade-class-1,
.grade_pre-kindergarten .grade-class-2,
.grade_pre-kindergarten .grade-class-3,
.grade_pre-kindergarten .grade-class-4,
.grade_pre-kindergarten .grade-class-5,
.grade_pre-kindergarten .grade-class-middleschool,
.grade_pre-kindergarten .grade-class-kindergarten,
.grade_kindergarten .grade-class-1,
.grade_kindergarten .grade-class-2,
.grade_kindergarten .grade-class-3,
.grade_kindergarten .grade-class-4,
.grade_kindergarten .grade-class-5,
.grade_kindergarten .grade-class-middleschool,
.grade_kindergarten .grade-class-pre-kindergarten,
.grade_1 .grade-class-kindergarten,
.grade_1 .grade-class-2,
.grade_1 .grade-class-3,
.grade_1 .grade-class-4,
.grade_1 .grade-class-5,
.grade_1 .grade-class-middleschool,
.grade_1 .grade-class-pre-kindergarten,
.grade_2 .grade-class-kindergarten,
.grade_2 .grade-class-1,
.grade_2 .grade-class-3,
.grade_2 .grade-class-4,
.grade_2 .grade-class-5,
.grade_2 .grade-class-middleschool,
.grade_2 .grade-class-pre-kindergarten,
.grade_3 .grade-class-kindergarten,
.grade_3 .grade-class-1,
.grade_3 .grade-class-2,
.grade_3 .grade-class-4,
.grade_3 .grade-class-5,
.grade_3 .grade-class-middleschool,
.grade_3 .grade-class-pre-kindergarten,
.grade_4 .grade-class-kindergarten,
.grade_4 .grade-class-1,
.grade_4 .grade-class-2,
.grade_4 .grade-class-3,
.grade_4 .grade-class-5,
.grade_4 .grade-class-middleschool,
.grade_4 .grade-class-pre-kindergarten,
.grade_5 .grade-class-kindergarten,
.grade_5 .grade-class-1,
.grade_5 .grade-class-2,
.grade_5 .grade-class-3,
.grade_5 .grade-class-4,
.grade_5 .grade-class-middleschool,
.grade_5 .grade-class-pre-kindergarten,
.grade_middleschool .grade-class-kindergarten,
.grade_middleschool .grade-class-1,
.grade_middleschool .grade-class-2,
.grade_middleschool .grade-class-3,
.grade_middleschool .grade-class-4,
.grade_middleschool .grade-class-5,
.grade_middleschool .grade-class-pre-kindergarten,
.discipline_PS .discipline-class-LS,
.discipline_PS .discipline-class-ESS,
.discipline_LS .discipline-class-PS,
.discipline_LS .discipline-class-ESS,
.discipline_ESS .discipline-class-PS,
.discipline_ESS .discipline-class-LS {
	display: none;
}

.lessonsPage .filters-area {
	display: flex;
}

.lessonsPage .filters-area > div {
	width: 50%;
    padding: 0 10px;
}

@media screen and (max-width: 600px) {
	.lessonsPage .filters-area {
		flex-direction: column;
	}
	.lessonsPage .filters-area > div {
		width: 100%;
		margin-top: 10px;
	}
}