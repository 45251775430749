.searchResult .containerSearchResult {
    flex-wrap: wrap;
}

.searchResult.noResult {
    background: var(--ks-color-practices);
    color: #fff;
    border-radius: 6px;
    height: 80px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin-top: 68px;
    align-items: center;
}

.searchResult .card-wrapper {
    padding: 10px;
}

.searchResult .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding-bottom: 10px;
    border-radius: 4px;
}

.searchResult .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.searchResult .container {
    padding: 2px 16px;
}

.searchResult .container h2 {
    color: var(--ks-color-practices);
    text-transform: uppercase;
    font-size: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.searchResult .container h2 span {
    color: #ccc;
}

.searchResult .containerSearchResult {
    display: flex;
}

.searchResult .title {
    font-size: 20px !important;
    color: var(--ks-color-practices);
}

@media only screen and (max-width: 600px) {
    .searchResult .containerSearchResult {
        flex-direction: column;
    }
}