.standards-container {
    margin-top: 60px;
    margin-bottom: 60px;
}

.standards-container .w3-row {
    align-items: stretch;
    display: flex;
}

@media (max-width: 991px) {
    .standards-container .w3-row {
        flex-direction: column;
    }
    .standards-container .w3-row .w3-third {
        width: 100%;
    }
}

.standards-container .standard-header {
    background-color: var(--swiper-theme-color);
    color: #fff;
    padding: 0 10px;
}

.standards-container .sep-container {
    background-color: #b8cce4;
}

.standards-container .sep-container h3 {
    background-color: #303996;
}

.standards-container .ccc-container {
    background-color: #d6e3bc;
}

.standards-container .ccc-container h3 {
    background-color: #db711c;
}

.standards-container .dci-container {
    background-color: #fbd4b4;
}

.standards-container .dci-container h3 {
    background-color: #7c9f36;
}

.standards-container .feature-container h3 {
    color: white;
    font-size: 18px;
    padding: 0 4px;
}

.standards-container .feature-container p {
    font-size: 15px;
    margin: 4px 0;
    padding: 0 4px;
}

.standards-container .feature-container p ul {
    margin: 0;
}